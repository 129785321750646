import { useRef, useEffect, useMemo } from 'react';
import { useTracking } from 'react-tracking';

export const useScrollDepthTracking = () => {
  const { trackEvent } = useTracking();
  const defaultValues = useMemo(
    () => ({
      pageHeight: 0,
      scrollDepth: 0,
      percentViewableOnLoad: 0,
      hasReachedBottom: false,
    }),
    [],
  );
  const scrollDepthRef = useRef(defaultValues);

  useEffect(() => {
    const pageHeight = document.documentElement.scrollHeight;
    const viewableLengthOnLoad = document.documentElement.getBoundingClientRect().bottom;
    const percentViewableOnLoad = viewableLengthOnLoad / pageHeight;
    scrollDepthRef.current = {
      ...scrollDepthRef.current,
      pageHeight,
      percentViewableOnLoad,
    };
    const scrollListener = () => {
      scrollDepthRef.current = {
        ...scrollDepthRef.current,
        scrollDepth: Math.max(
          Math.round(window.scrollY),
          scrollDepthRef.current.scrollDepth,
        ),
        hasReachedBottom:
          Math.abs(
            scrollDepthRef.current.pageHeight -
              document.documentElement.clientHeight -
              scrollDepthRef.current.scrollDepth,
          ) <= 1,
      };
    };

    const beforeUnloadListener = () => {
      const scrollPercentage =
        scrollDepthRef.current.pageHeight !== 0
          ? (scrollDepthRef.current.scrollDepth / scrollDepthRef.current.pageHeight) * 100
          : 0;
      const viewableOnLoadPercentage = scrollDepthRef.current.percentViewableOnLoad
        ? scrollDepthRef.current.percentViewableOnLoad * 100
        : undefined;

      viewableOnLoadPercentage &&
        trackEvent({
          event: 'Page Scroll',
          properties: {
            scrollDepth: `${scrollPercentage}%`,
            pageLength: scrollDepthRef.current.pageHeight,
            pageEnd: scrollDepthRef.current.hasReachedBottom ? 1 : 0,
            viewableOnLoad: `${viewableOnLoadPercentage}%`,
          },
        });
      scrollDepthRef.current = defaultValues;
    };

    document.addEventListener('scroll', scrollListener);
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [defaultValues, trackEvent]);
};
