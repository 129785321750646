import {
  Flex,
  Support,
  spacing,
  grey,
  Container,
  black,
} from '@pelotoncycle/design-system';
import Close from '@pelotoncycle/design-system/icons/interaction/Close';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import styled from 'styled-components';
type UpdatedNoticeType = {
  header: string;
  documents: string[];
  newPolicyDate?: string;
  isCookieVisible?: boolean;
};

// These styles mirror the ones applied by OneTrust when the notice is within the cookie popup
// Without these, the notice would be styled differently when there's no cookie popup visible
const Paragraph = styled.p`
  color: dimgray;
  margin: 0 0 1em 0;
  font-family: inherit;
  line-height: normal;
  font-weight: 400;
  -webkit-font-smoothing: auto;

  a {
    color: #3860be;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const UpdateNotice: React.FC<UpdatedNoticeType> = ({
  header,
  newPolicyDate,
  documents,
  isCookieVisible,
}) => {
  return (
    <Container
      id="policy-updates"
      data-test-id="policy-updates"
      padding={`${spacing[16]} 25px ${spacing[12]} !important`}
      gap={spacing[4]}
      color={grey[80]}
      maxWidth={{
        mobile: isCookieVisible ? 'unset' : '92%',
        tablet: isCookieVisible ? 'unset' : '375px',
      }}
      margin={{
        mobile: `${spacing[12]} 0 ${spacing[12]} 0`,
        desktop: `${spacing[12]} 0 ${spacing[12]} ${spacing[16]}`,
      }}
      style={{
        bottom: isCookieVisible ? 'unset' : 0,
        position: isCookieVisible ? 'unset' : 'fixed',
        zIndex: 2147483645,
        background: `${grey[30]} !important`,
      }}
    >
      {/* we must use fontSize instead of small tags below because of onetrust's overrides. */}
      <Flex justifyContent="space-between" alignItems="center">
        <Support style={{ fontWeight: 500, fontSize: spacing[12], lineHeight: '18px' }}>
          {header}
        </Support>
        <Flex style={{ marginRight: '-10px !important', border: 'none' }}>
          <Close name="close" data-testid="close-icon" height={16} primaryColor={black} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={spacing[8]}>
        {newPolicyDate && (
          <Support
            size="small"
            style={{ fontWeight: 500, fontSize: spacing[12], lineHeight: '18px' }}
          >
            {newPolicyDate}
          </Support>
        )}
        {documents.map(document => (
          <Container key={document} style={{ fontSize: spacing[12], lineHeight: '18px' }}>
            <Markdown options={{ overrides: { p: Paragraph } }}>{document}</Markdown>
          </Container>
        ))}
      </Flex>
    </Container>
  );
};

export default UpdateNotice;
