import React from 'react';
import type { Locale } from '@peloton/internationalize';
import shouldHideContent from '@ecomm/copy/helpers/shouldHideContent';
import useContentAggregate from '../aggregates/useContentAggregate';
import privacyPolicy from './privacyPolicyUpdates';

const usePrivacyPolicy = (locale: Locale) =>
  useContentAggregate(privacyPolicy.entryId, false, locale);

export const usePolicyDocuments = (content: { [key: string]: {} }) => {
  const entries = content?.['privacyPolicy'] || {};
  const documents = React.useMemo(() => {
    return Object.values(entries)
      .map(entry => entry?.['value'])
      .filter(value => !shouldHideContent(value));
  }, [content]);

  return {
    documents,
  };
};

export const useNativeCountryNames = () => {
  const { content } = useContentAggregate(privacyPolicy.entryId);
  return content?.localePicker.nativeCountryNames;
};

export default usePrivacyPolicy;
