import { datadogRum } from '@datadog/browser-rum';
import type { RumInitConfiguration } from '@datadog/browser-rum';
import { DD_RUM_SAMPLE_RATE, MONITORING_ENV } from '@peloton/app-config';
import { beforeSendHandler } from '@peloton/error-reporting/datadog/beforeSendHandler';

import type { ErrorProperties, ErrorReporterFactory } from '../ErrorReporter';

const datadogBrowserReporter: ErrorReporterFactory = () => ({
  reportError: (error: Error, properties: ErrorProperties) => {
    // check oneTrust Performance cookie consent before creating DD
    const win: any = window;
    const hasConsentToPerfCookies = win.OnetrustActiveGroups?.includes('C0002') || false;
    if (!hasConsentToPerfCookies) {
      return null;
    }

    /* This is a workaround to fix errors not being posted for deploy previews
       Somehow datadogRum wasn't available after CDN initialization in deploy previews (CDN being blocked?)
       Added a check here to initialize datadog if it wasn't available */
    if (!datadogRum.getInternalContext()) {
      const sampleRateAppConfig: number = Number.parseInt(DD_RUM_SAMPLE_RATE!) || 0;
      const sampleRate = window.navigator.userAgent.match(/HeadlessChrome|Prerender/)
        ? 0
        : sampleRateAppConfig;
      const config: RumInitConfiguration = {
        applicationId: 'd410086b-125a-4c4e-827a-76e0ac5ac591',
        clientToken: 'pub61d267c2792aa455b002a59e01b73caf',
        site: 'datadoghq.com',
        service: 'www',
        env: process.env.MONITORING_ENV,
        version: process.env.COMMIT_REF,
        sessionSampleRate: sampleRate,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
        beforeSend: beforeSendHandler,
      };
      // TODO: figure out nginx rewrites for this proxy on DPs
      if (MONITORING_ENV !== 'uat') {
        config.proxy = '/ddforward';
      }
      datadogRum.init(config);
    }

    if (datadogRum.getInternalContext()) {
      if (typeof properties === 'object' && properties !== null) {
        const { tags, extra, ...extraProperties } = properties;
        return datadogRum.addError(error, {
          tags,
          extra: { ...extra, ...extraProperties },
        });
      }

      return datadogRum.addError(error, undefined);
    }
    return null;
  },
});

export default datadogBrowserReporter;
