import { ALLOW_PREVIEW_COPY } from '@peloton/app-config';
import { useIsDraftMode } from '@content/client/DraftModeContext';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

const useIsPreviewCopyEnabled = () => {
  const draftMode = useIsDraftMode();
  const previewCopyToggle = useIsToggleActive()('previewCopy');

  return ALLOW_PREVIEW_COPY || previewCopyToggle || draftMode;
};

export default useIsPreviewCopyEnabled;
