import { SpeedInsights } from '@vercel/speed-insights/react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';
import { reportFocusMethod } from '@peloton/accessibility';
import UnsupportedBrowser from '@peloton/browser/UnsupportedBrowser';
import ChatStyles from '@peloton/drift/ChatStyles';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import ErrorHandler from '@peloton/next/components/ErrorHandler';
import Locales from '@peloton/next/components/Locales';
import Scripts from '@peloton/next/components/Scripts';
import nav from '@content/client/www/nav/nav';
import pageBuilderNav from '@content/client/www/nav/pageBuilderNav';
import type { AvailabilityByProduct } from '@ecomm/product-states/models/context';

// CookieSettinsOpen is a dynamic import because it depends on window.OneTrust
const CookieSettingsOpen = dynamic(
  () => import('@peloton/next/components/CookieSettingsOpen'),
  { ssr: false },
);
const Providers = dynamic(() => import('@peloton/next/components/Providers'));
export type Props = AppProps<{
  productState: AvailabilityByProduct;
  fallback: any;
  buildTime: number;
  locale: string;
}>;

export const App: React.FC<React.PropsWithChildren<Props>> = ({
  Component,
  pageProps: { fallback, productState, buildTime, locale, ...props },
}) => {
  const router = useRouter();

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      reportFocusMethod(document);
    }
  }, []);

  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  return (
    <>
      <Head>
        {/* Preloaded Nav Builder Script */}
        <link
          rel="preload"
          as="fetch"
          crossOrigin="anonymous"
          href={`https://www.onepeloton.com/api/pelo-content-entry-handler/${locale}/${nav.entryId}`}
        />
        <link
          rel="preload"
          as="fetch"
          crossOrigin="anonymous"
          href={`https://www.onepeloton.com/api/page-builder-entry-handler/${locale}/${pageBuilderNav.entryId}`}
        />
      </Head>
      <UnsupportedBrowser />
      <ChatStyles />
      <Providers
        buildTime={buildTime}
        fallback={fallback}
        productState={productState}
        locale={locale}
      >
        <Scripts />
        <Script
          src="/appsFlyerSmartScript.js"
          id="appsflyer-js"
          onError={e => reportError(e, { script: '/appsFlyerSmartScript.js' })}
        />
        <Locales />
        <CookieSettingsOpen />
        <ErrorHandler>
          <div id="download-app-banner" />
          <Component {...props} />
          <SpeedInsights
            route={router.pathname}
            scriptSrc={process.env.SPEED_INSIGHTS_URL}
          />
        </ErrorHandler>
      </Providers>
    </>
  );
};
