import { extractAggregateFields } from '../lib/extractAggregateFields';
import { globalEntriesConfig } from '../www/aggregates/aggregateMap';

const identity = <T = {}>(data: T) => data;

export const parseAggregateData = (data: any, entryId: string) => {
  const config = globalEntriesConfig[entryId];
  const mapToView = config.mapToView ?? identity;
  const customExtractor = config.customExtractor ?? extractAggregateFields;
  return mapToView(customExtractor(data));
};

export const parsePageBuilderAggregateData = (data: any, entryId: string) => {
  const config = globalEntriesConfig[entryId];
  const customExtractor = config.customExtractor ?? extractAggregateFields;
  return customExtractor(data);
};
